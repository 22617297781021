<template>
  <div class="lifeheath">
    <div class="volunteer-continar">
        <div class="redtitle">
        <span>生命健康</span>
       </div>
       <div class="lifeheathcontent">
         <div class="heathbanners">
           <Heathbanners :banners="heathlist"></Heathbanners>
         </div>
         <Heathnews :newslist="heathlist" @childByValue="childByValue"></Heathnews>
       </div>
    </div> 
  </div>
</template>

<script>
import Heathbanners from './components/heathbanners'
import Heathnews from './components/heathnews'
import apiUrls from '@api'
export default {
  components : {
    Heathbanners,
    Heathnews
  },
  name: 'Lifeheath',
  data () {
      return {
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 1,
      heathlist: []
      }
    },
    created () {
      this.getheathlist()
    },
    methods : {
    handleSizeChange(val) {

    },
    handleCurrentChange(val) {

    },
    getheathlist () {
      apiUrls.getlearnplat({node: 'xxpt_smjk'}).then(res=>{
      this.heathlist= res.results.data
      })
    },
    childByValue (childByValue) {
      this.heathlist = childByValue
    }
    }
}
</script>

<style lang="less" scoped>
.lifeheath {
  background-color: #f5f5f5;
 .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
         .redtitle  {
          margin-top: 10px;
          font-size: 23px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
              &:nth-child(1) {
              display: flex;
              &::before {
              content: "";
              display: block;
              height: 20px;
              width: 20px;
              background: url('../../../assets/img/title.png') no-repeat center center;
            }
          }
            &:nth-child(2) {
                color: #ABABAB;
                font-size: 17px;
            }
        }
   
      }
      .lifeheathcontent {
        min-height: 300px;
        background-color: #ffffff;  
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        padding-top: 56px;
        .heathbanners {
          height: 480px;
          // background-color: pink;
        }
      }
    }
  }
</style>
